import { CasesStatistics } from "./CasesStatistics";
import { FirmDocumentFoldersStatistics } from "./FirmDocumentFoldersStatistics";
import { ProvidersStatistics } from "./ProvidersStatistics";
import { TemplateFilesStatistics } from "./TemplateFilesStatistics";
import { IntakesStatistics } from "./IntakesStatistics";

export interface MigrationStatistics {
  organizationId: string;
  allocatedSharepointSpaceInGb?: number;
  usedSharepointSpaceInGb?: number;
  casesStatistics: CasesStatistics;
  intakesStatistics: IntakesStatistics;
  providersStatistics: ProvidersStatistics;
  firmDocumentFoldersStatistics: FirmDocumentFoldersStatistics;
  templateFilesStatistics: TemplateFilesStatistics;
}

export interface FilesStatistics {
  TotalFilesSizeInGb: number;
  TotalFilesSizeInMb: number;
  MigratedFilesSizeInGb: number;
  MigratedFilesSizeInMb: number;
  MigratedPercentage: number;
  TotalFinishedFiles: number;
  TotalFailedFiles: number;
  TotalNotFoundFiles: number;
  TotalNotStartedFiles: number;
  TotalFlaggedFiles: number;
}

export const calculateTotalFileSize = (
  statistics: MigrationStatistics,
  inMb = false,
): number => {
  const {
    casesStatistics,
    intakesStatistics,
    providersStatistics,
    firmDocumentFoldersStatistics,
    templateFilesStatistics,
  } = statistics;
  const totalSize = inMb
    ? casesStatistics.totalFilesSizeInMb +
      intakesStatistics.totalFilesSizeInMb +
      providersStatistics.totalFilesSizeInMb +
      firmDocumentFoldersStatistics.totalFilesSizeInMb +
      templateFilesStatistics.totalFilesSizeInMb
    : casesStatistics.totalFilesSizeInGb +
      intakesStatistics.totalFilesSizeInGb +
      providersStatistics.totalFilesSizeInGb +
      firmDocumentFoldersStatistics.totalFilesSizeInGb +
      templateFilesStatistics.totalFilesSizeInGb;
  return totalSize;
};

export const calculateMigratedFilesSize = (
  statistics: MigrationStatistics,
  inMb = false,
): number => {
  let migratedFilesSize = 0;
  if (inMb) {
    migratedFilesSize +=
      statistics.casesStatistics.totalMigratedFilesSizeInMb || 0;
    migratedFilesSize +=
      statistics.intakesStatistics.totalMigratedFilesSizeInMb || 0;
    migratedFilesSize +=
      statistics.providersStatistics.totalMigratedFilesSizeInMb || 0;
    migratedFilesSize +=
      statistics.firmDocumentFoldersStatistics.totalMigratedFilesSizeInMb || 0;
    migratedFilesSize +=
      statistics.templateFilesStatistics.totalMigratedFilesSizeInMb || 0;
  } else {
    migratedFilesSize +=
      statistics.casesStatistics.totalMigratedFilesSizeInGb || 0;
    migratedFilesSize +=
      statistics.intakesStatistics.totalMigratedFilesSizeInGb || 0;
    migratedFilesSize +=
      statistics.providersStatistics.totalMigratedFilesSizeInGb || 0;
    migratedFilesSize +=
      statistics.firmDocumentFoldersStatistics.totalMigratedFilesSizeInGb || 0;
    migratedFilesSize +=
      statistics.templateFilesStatistics.totalMigratedFilesSizeInGb || 0;
  }
  return migratedFilesSize;
};

export const calculateFilesStatistics = (
  statistics: MigrationStatistics,
): FilesStatistics => {
  const {
    casesStatistics,
    intakesStatistics,
    providersStatistics,
    firmDocumentFoldersStatistics,
    templateFilesStatistics,
  } = statistics;

  const totalFileSizeInGb = calculateTotalFileSize(statistics);
  const migratedFilesSizeInGb = calculateMigratedFilesSize(statistics);
  const totalFileSizeInMb = calculateTotalFileSize(statistics, true);
  const migratedFilesSizeInMb = calculateMigratedFilesSize(statistics, true);

  const totalFinishedFiles =
    casesStatistics.filesMigrated +
    intakesStatistics.filesMigrated +
    providersStatistics.filesMigrated +
    firmDocumentFoldersStatistics.filesMigrated +
    templateFilesStatistics.filesMigrated;
  const totalFailedFiles =
    casesStatistics.filesInterrupted +
    intakesStatistics.filesInterrupted +
    providersStatistics.filesInterrupted +
    firmDocumentFoldersStatistics.filesInterrupted +
    templateFilesStatistics.filesInterrupted;
  const totalNotFoundFiles =
    casesStatistics.filesNotFound +
    intakesStatistics.filesNotFound +
    providersStatistics.filesNotFound +
    firmDocumentFoldersStatistics.filesNotFound +
    templateFilesStatistics.filesNotFound;
  const totalFlaggedFiles = 
    casesStatistics.filesFlagged + 
    intakesStatistics.filesFlagged;
  const totalNumberOfFiles =
    casesStatistics.totalNumberOfFiles +
    intakesStatistics.totalNumberOfFiles +
    providersStatistics.totalNumberOfFiles +
    firmDocumentFoldersStatistics.totalNumberOfFiles +
    templateFilesStatistics.totalNumberOfFiles;
  const totalNotStartedFiles =
    totalNumberOfFiles -
    totalFinishedFiles -
    totalFailedFiles -
    totalNotFoundFiles -
    totalFlaggedFiles;

  return {
    TotalFilesSizeInGb: totalFileSizeInGb,
    TotalFilesSizeInMb: totalFileSizeInMb,
    MigratedFilesSizeInGb: migratedFilesSizeInGb,
    MigratedFilesSizeInMb: migratedFilesSizeInMb,
    MigratedPercentage: (migratedFilesSizeInGb / totalFileSizeInGb) * 100,
    TotalFinishedFiles: totalFinishedFiles,
    TotalFailedFiles: totalFailedFiles,
    TotalNotFoundFiles: totalNotFoundFiles,
    TotalNotStartedFiles: totalNotStartedFiles,
    TotalFlaggedFiles: totalFlaggedFiles,
  };
};
