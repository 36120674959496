import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import CasesStatisticsCard from "../components/Organization/CasesStatisticsCard";
import FirmDocumentsStatisticsCard from "../components/Organization/FirmDocumentsStatisticsCard";
import IntakesStatisticsCard from "../components/Organization/IntakesStatisticsCard";
import OverallStatisticsCard from "../components/Organization/OverallStatisticsCard";
import ProvidersStatisticsCard from "../components/Organization/ProvidersStatisticsCard";
import TemplatesStatisticsCard from "../components/Organization/TemplatesStatisticsCard";
import OutlinedDiv from "../components/common/OutlinedDiv";
import {
  convertUtcToLocal,
  roundToTwoDecimalPoints,
} from "../helpers";
import { OrganizationWithStatistics } from "../models/OrganizationWithStatistics";
import OrganizationsRepository from "../repositories/OrganizationsRepository";
import UsersRepository from "../repositories/UsersRepository";
import {User} from "../models/User";
import GenericAutocomplete from "../components/common/GenericAutocomplete";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Tooltip from '@mui/joy/Tooltip';
import { useQueryClient } from "react-query";
import {
  getEventStepTypeColor,
  MigrationEventStepLabels,
  MigrationEventStepType
} from "../models/Statistics/MigrationEventStepType";
import { MigrationTypeLabels } from "../models/Statistics/MigrationType";
import {MigrationCycleStatistics} from "../models/Statistics/MigrationCycleStatistics";
import ClientInfoRepository from "../repositories/ClientInfoRepository";

import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Grid,
  Link,
  Select,
  Skeleton,
  Option,
  Table,
  Typography,
} from "@mui/joy";
import {MigrationEventTypeLabels} from "../models/Statistics/MigrationEventType";

interface OrganizationPageProps {
  organizationId: string;
}

const OrganizationPage: React.FC<OrganizationPageProps> = ({
  organizationId,
}) => {
  const [organization, setOrganization] =
    useState<OrganizationWithStatistics | undefined>(undefined);
  const [users, setUsers] =
    useState<User[]>([]);
  const [selectedUser, setSelectedUser] =
    useState<{id: string, label: string} | undefined>(undefined);
  const [clientIds, setClientIds] =
    useState<string[]>([]);
  const [selectedClientId, setSelectedClientId] =
    useState<string>("");
  const [migrationCycleStatistics, setMigrationCycleStatistics] =
    useState<MigrationCycleStatistics[] | undefined>([]);

  const queryClient = useQueryClient();
  const organizationsRepository = new OrganizationsRepository();
  const usersRepository = new UsersRepository();
  const clientRepository = new ClientInfoRepository();

  const organizationQuery = useQuery(
    ["organization", organizationId],
    async () => {
      return await organizationsRepository.getOrganization(organizationId);
    },
    {
      cacheTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchOnMount: true,
    },
  );

  const userQuery = useQuery(
    "users",
    async () => {
      return await usersRepository.getUsers();
    },
    {
      cacheTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000,
      refetchOnMount: false,
    },
  );

  const clientIdsQuery = useQuery(
    ["clientIds", organizationId],
    async () => {
      return await organizationsRepository.getOrganizationClientIds(organizationId);
    },
    {
      cacheTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchOnMount: false,
    },
  );

  const clientMigrationCyclesQuery = useQuery(
    ["clientMigrationCycles", selectedClientId],
    async () => {
      if (!selectedClientId) return;
      return await clientRepository.getClientMigrationCycles(selectedClientId);
    },
    {
      cacheTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchOnMount: false,
      enabled: !!selectedClientId,
    }
  );

  useEffect(() => {
    if (organizationQuery.data) {
      setOrganization(organizationQuery.data);
      setSelectedUser({
        id: organizationQuery.data.assignedUser.id,
        label: organizationQuery.data.assignedUser.username,
      });
    }

    if (userQuery.data) {
      setUsers(userQuery.data.data);
    }

    if (clientIdsQuery.data && clientIdsQuery.data !== clientIds) {
      setClientIds(clientIdsQuery.data);
      setSelectedClientId(clientIdsQuery.data[0]);
    }

    if (clientMigrationCyclesQuery.data) {
      setMigrationCycleStatistics(clientMigrationCyclesQuery.data);
    }
  }, [
    userQuery.data,
    organizationQuery.data,
    clientIdsQuery.data,
    clientMigrationCyclesQuery.data,
    organizationId
  ]);

  const userOptions = users.map(user => ({
    id: user.id,
    label: user.username,
  }));
  
  const { isLoading, isError } = organizationQuery;
  if (isLoading || isError || !!!organization) {
    return <></>;
  }
  const { intactId, name, sharepointSiteUrl, migrationStatistics } =
    organization as OrganizationWithStatistics;
  const {
    casesStatistics,
    intakesStatistics,
    providersStatistics,
    firmDocumentFoldersStatistics,
    templateFilesStatistics,
  } = migrationStatistics;
  const allocatedSpace = migrationStatistics.allocatedSharepointSpaceInGb
    ? roundToTwoDecimalPoints(migrationStatistics.allocatedSharepointSpaceInGb)
    : null;
  const usedSpace = migrationStatistics.usedSharepointSpaceInGb
    ? roundToTwoDecimalPoints(migrationStatistics.usedSharepointSpaceInGb)
    : null;
  const usedPercentage =
    usedSpace && allocatedSpace
      ? `${((parseFloat(usedSpace) / parseFloat(allocatedSpace)) * 100).toFixed(
        2,
      )}%`
      : null;
  const shouldRenderSharepointInfo =
    sharepointSiteUrl || allocatedSpace || usedSpace;

  const handleSelectUser = async (selectedOption: { id: string; label: string }) => {
    setSelectedUser(selectedOption);
    try {
      await organizationsRepository.updateOrganizationAssignedUser(organizationId, selectedOption.id);
      await queryClient.invalidateQueries("organizations");
    } catch (error) {
      console.error(error);
    }
  };
  
  return (
    <>
      <Box width="100%" height={"100%"} padding={0}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', my: 1}}>
          <Typography level="h2" textColor="text.primary" sx={{ pb: 1 }}>
            {intactId} - {name}
          </Typography>
          <Skeleton
            animation="pulse"
            variant="inline"
            loading={userQuery.isLoading}
          >
            <GenericAutocomplete
              options={userOptions}
              inputValue={selectedUser ?? {id: '', label: ''}}
              setValue={handleSelectUser}
              placeholder="Assigned user"
              width="350px"
              startDecorator={
                <Tooltip title="Assigned user" variant="solid">
                  <AssignmentIndIcon sx={{ fontSize: 26, mt: 0.4 }}/>
                </Tooltip>
              }
            />
          </Skeleton>
        </Box>

        <Grid container spacing={2} height={"100%"}>
          
          {shouldRenderSharepointInfo ? (
            <Grid xs={12} lg={12} display={"flex"}>
              <Card variant="outlined" sx={{ width: "100%" }}>
                <Typography level="h3" sx={{ paddingBottom: "0px" }}>
                  Sharepoint
                </Typography>
                <CardContent>
                  <OutlinedDiv label="">
                    {sharepointSiteUrl && (
                      <Link
                        href={sharepointSiteUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="primary"
                      >
                        {sharepointSiteUrl}
                      </Link>
                    )}

                    {usedSpace && (
                      <Typography level="body-md" textColor="text.primary">
                        Used Space (GB): {usedSpace} ({usedPercentage})
                      </Typography>
                    )}
                    {allocatedSpace && (
                      <Typography level="body-md" textColor="text.primary">
                        Allocated Space (GB): {allocatedSpace}
                      </Typography>
                    )}

                    <Typography
                      level="body-md"
                      textColor="text.primary"
                    ></Typography>
                  </OutlinedDiv>
                </CardContent>
              </Card>
            </Grid>
          ) : null}
          
          {clientIds.length > 0 ? (
            <Grid xs={12} lg={12} display={"flex"}>
              <Card variant="outlined" sx={{ width: "100%" }}>
                <Typography level="h3" sx={{ paddingBottom: "0px" }}>
                  Migration cycles
                </Typography>
                <CardContent>
                  <OutlinedDiv label="">
                    {clientIds.length > 1 ? (
                      <FormControl sx={{width: "30%", minWidth: 375, mb: 1}}>
                        <FormLabel>Client Id</FormLabel>
                        <Skeleton
                          variant="inline"
                          animation="pulse"
                          loading={clientIdsQuery.isLoading}>
                          <Select
                            value={selectedClientId || ''}
                            onChange={(event, value) => {
                              setSelectedClientId(value ?? '');
                            }}
                          >
                            {clientIds.map(id => (
                              <Option key={id} value={id}>
                                {id}
                              </Option>
                            ))}
                          </Select>
                        </Skeleton>
                      </FormControl>
                    ) : null}

                    <Skeleton variant="inline" loading={clientMigrationCyclesQuery.isLoading}>
                      <Table noWrap>
                        <thead>
                        <tr>
                          <th>
                            Type
                          </th>
                          <th>
                            One before latest cycle
                          </th>
                          <th>
                            Latest cycle
                          </th>
                        </tr>
                        </thead>
  
                        <tbody>
                        {migrationCycleStatistics?.map((cycle, index) => (
                          <tr style={{height: '50px'}}>

                            <td>
                              <Typography level="body-md">
                                {MigrationTypeLabels[cycle.migrationType]}
                              </Typography>
                            </td>

                            <td>
                              <Box sx={{display: 'flex', alignItems: 'center', gap: 1.5}}>
                                {
                                  cycle.secondLatestMigration ? (
                                    <Box sx={{display: 'flex', alignItems: 'center', gap: 1.5}}>
                                      <Box sx={{minWidth: 0}}>
                                        <Typography noWrap level="body-sm">
                                          <Typography
                                            fontWeight="lg"
                                            component="span"
                                            sx={{color: "white"}}
                                          >
                                            {MigrationEventTypeLabels[cycle.secondLatestMigration.eventType]}
                                          </Typography>
                                        </Typography>
                                        
                                        <Typography noWrap level="body-sm">
                                          <Typography
                                            fontWeight="lg"
                                            component="span"
                                            color={getEventStepTypeColor(MigrationEventStepType.Started)}
                                          >
                                            {MigrationEventStepLabels[MigrationEventStepType.Started]}
                                          </Typography>
                                          {': '}
                                          {convertUtcToLocal(cycle.secondLatestMigration.startDate)}
                                        </Typography>

                                        {
                                          cycle.secondLatestMigration.eventStep != MigrationEventStepType.Started ? (
                                            <Typography noWrap level="body-sm">
                                              <Typography
                                                fontWeight="lg"
                                                component="span"
                                                color={getEventStepTypeColor(cycle.secondLatestMigration.eventStep)}
                                              >
                                                {MigrationEventStepLabels[cycle.secondLatestMigration.eventStep]}
                                              </Typography>
                                              {': '}
                                              {convertUtcToLocal(cycle.secondLatestMigration.endDate)}
                                            </Typography>
                                          ) : (
                                            <Typography
                                              fontWeight="lg"
                                              component="span"
                                              color="warning"
                                            >
                                              In Progress...
                                            </Typography>
                                          )
                                        }
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Typography noWrap level="body-sm">
                                      None
                                    </Typography>
                                  )
                                }
                              </Box>
                            </td>

                            <td>
                              <Box sx={{display: 'flex', alignItems: 'center', gap: 1.5}}>
                                {
                                  cycle.latestMigration ? (
                                    <Box sx={{display: 'flex', alignItems: 'center', gap: 1.5}}>
                                      <Box sx={{minWidth: 0}}>
                                        <Typography noWrap level="body-sm">
                                          <Typography
                                            fontWeight="lg"
                                            component="span"
                                            sx={{color: "white"}}
                                          >
                                            {MigrationEventTypeLabels[cycle.latestMigration.eventType]}
                                          </Typography>
                                        </Typography>
                                        
                                        <Typography noWrap level="body-sm">
                                          <Typography
                                            fontWeight="lg"
                                            component="span"
                                            color={getEventStepTypeColor(MigrationEventStepType.Started)}
                                          >
                                            {MigrationEventStepLabels[MigrationEventStepType.Started]}
                                          </Typography>
                                          {': '}
                                          {convertUtcToLocal(cycle.latestMigration.startDate)}
                                        </Typography>

                                        {
                                          cycle.latestMigration.eventStep != MigrationEventStepType.Started ? (
                                            <Typography noWrap level="body-sm">
                                              <Typography
                                                fontWeight="lg"
                                                component="span"
                                                color={getEventStepTypeColor(cycle.latestMigration.eventStep)}
                                              >
                                                {MigrationEventStepLabels[cycle.latestMigration.eventStep]}
                                              </Typography>
                                              {': '}
                                              {convertUtcToLocal(cycle.latestMigration.endDate)}
                                            </Typography>
                                          ) : (
                                            <Typography
                                              fontWeight="lg"
                                              component="span"
                                              color="warning"
                                            >
                                              In Progress...
                                            </Typography>
                                          )
                                        }
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Typography noWrap level="body-sm">
                                      None
                                    </Typography>
                                  )
                                }
                              </Box>
                            </td>
                          </tr>
                        ))}
                        <tr></tr>
                        </tbody>
                      </Table>
                    </Skeleton>
                  </OutlinedDiv>
                </CardContent>
              </Card>
            </Grid>
          ) : null}

          <Grid xs={12} lg={12} display={"flex"}>
            <OverallStatisticsCard statistics={migrationStatistics}/>
          </Grid>
          <Grid xs={12} lg={12} display={"flex"}>
            <CasesStatisticsCard statistics={casesStatistics}/>
          </Grid>
          <Grid xs={12} lg={6} display={"flex"}>
            <IntakesStatisticsCard statistics={intakesStatistics}/>
          </Grid>
          <Grid xs={12} lg={6} display={"flex"}>
            <ProvidersStatisticsCard statistics={providersStatistics}/>
          </Grid>
          <Grid xs={12} lg={6} display={"flex"}>
            <FirmDocumentsStatisticsCard
              statistics={firmDocumentFoldersStatistics}
            />
          </Grid>
          <Grid xs={12} lg={6} display={"flex"}>
            <TemplatesStatisticsCard statistics={templateFilesStatistics}/>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default OrganizationPage;
